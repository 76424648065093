
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function NotFoundPage({ location }) {

  // TODO: add article info: https://developers.google.com/search/docs/advanced/structured-data/article
  
  return (
    <Layout location={location}>
      <Seo title="404: Not found" />
      <div className="min-h-full px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-rose-400 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
              </div>
              <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a href="/" className="btn-primary"> Go to home page </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
